/*
 * @Author: mayu.ex 15163353598@163.com
 * @Date: 2024-03-20 17:56:07
 * @LastEditors: mayu.ex 15163353598@163.com
 * @LastEditTime: 2024-03-28 10:52:43
 * @FilePath: \B2B\src\views\tradeInoldForNew\detail\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { queryDetail,updateAijiaOldMachineOrderStatus } from './../api'
export default {
  data() {
    return {
     
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
            path: "/tradeInoldForNew",
            name: "tradeInoldForNew",
            title: "旧机订单列表"
          },
        {
          path: "/tradeInoldForNewDetail",
          name: "",
          title: "订单详情"
        }
      ],
      TVIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/tv.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797185&Signature=gmnN9cJCWEJqHgoSsysnVf5WwCg%3D',
      BXIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/bx.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797122&Signature=iggDn2fhqfqW1aeaxbMIli9E7jE%3D',
      KTIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/kt.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797163&Signature=ur2HLjmIfnNwD%2FnqD%2FPENZWvEso%3D',
      XYJIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/xyj.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797202&Signature=xKtbvv1WIPgFj3z8DIBepnm%2BuD8%3D',
      CWIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/cw.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2036632507&Signature=MIXXULYnj%2BujgpTdwhp3X2Z4l3M%3D',
      SJIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/sjbf.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2036632411&Signature=oIGDhtcrZ8b%2Bb4lvoWf3mCQ6r5g%3D',
      LGIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/lgbf.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2036632490&Signature=3cnthLClyB%2B5YGzMW6%2BxZEuSZpw%3D',
      pageLoadFlag:false,
      detailInfo:{},
      visibleInfo:false,
      message:'',
      type:''
     
    };
  },
  mounted() {
    if(this.$route.query.id){
      this.getDeatilInfo(this.$route.query.id)
    }
  },
  methods: {
    // 查询详情
    getDeatilInfo(id){
       this.pageLoadFlag=true
        queryDetail({recycleOrder:id}).then(res=>{
          if(res.data.code == 0) {
            this.pageLoadFlag=false
            this.detailInfo = res.data.data
          }else {
            this.detailInfo = {}
            this.pageLoadFlag=false
          }
        }).catch(err=>{
          this.pageLoadFlag=false
        })
    },
       // 批量自行处理
    handle(e) {
      this.type = e
      switch (e) {
        case 'CANCEL':
          this.message='请确认是否取消该收旧信息！'
          break;
        case 'TO_RECYCLED':
          this.message='请确认是否已上门拉回旧机！'
            break;
        case 'DONE':
          this.message='请确认该旧机是否自行处理！'
            break;
        case 'NOTICED':
          this.message='请确认是否通知收旧商回收旧机！'
          break;
      }
      this.visibleInfo = true
    },
    //  更新状态
    delect() {
      let data = {
        dealMethod:(this.type=='NOTICED' || this.type=='DONE')?this.type=='NOTICED'?2:1:'',
        idList:[this.detailInfo.id],
        status:this.type
      }
      this.pageLoadFlag = true
      updateAijiaOldMachineOrderStatus(data).then(res=>{
        if(res.data.code == 0){
          this.$message.success('操作成功')
          this.getDeatilInfo(this.$route.query.id)
          this.pageLoadFlag = false
        }else {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false
        }
      }).catch(err=>{
        this.pageLoadFlag = false
      })
    },
    goBack() {
      // this.$router.go(-1)
      window.close()
    }
  },
 
};
